import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Service = () => {
  return (
    <section className="bg-neutral-200 py-8">
      <div className="max-w-screen-xl mx-auto">
        <div
          className="px-4 py-16 mx-auto max-w-screen-2xl sm:px-6 lg:px-8"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 ">
            <div className="relative flex items-center bg-white">
              <span className="hidden lg:inset-y-0 lg:absolute lg:w-16 lg:bg-white lg:block lg:-right-16"></span>

              <div className="p-8 sm:p-16 lg:p-24">
                <h2 className="text-2xl font-bold sm:text-3xl">
                  Niedziela 10.00
                  <br />
                  Środa 18.30
                </h2>

                <p className="mt-8">
                  Wypoczywasz w <b>Wiśle</b> i poszukujesz Kościoła? Dobrze
                  trafiłeś! Z przyjemnością powitamy Cię w naszym zborze
                  przy&nbsp;<b>ul. Dziechcinka 3a</b>
                </p>
              </div>
            </div>
            <div
              className="relative z-10 lg:py-16"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="relative h-64 sm:h-80 lg:h-full">
                <StaticImage
                  className="absolute inset-0 object-cover w-full h-full"
                  data-sal="slide-right"
                  data-sal-easing="ease"
                  data-sal-duration="500"
                  src="../../assets/photo/church.jpg"
                  alt="nabozeństwo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Service
