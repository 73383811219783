import React from "react"
import Hero from "../components/MainPage/Hero"
import PageTemplate from "../templates/PageTemplate"
import Helmet from "react-helmet"
import Welcome from "../components/MainPage/Welcome"
import Service from "../components/MainPage/Service"

export default function Home() {
  return (
    <PageTemplate>
      <Helmet title="Moja oaza" />
      <Hero />
      <Welcome />
      <Service />
    </PageTemplate>
  )
}
