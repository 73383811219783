import React from "react"
import styled from "styled-components"
import background from "../../assets/photo/background.jpg"

const StyledHero = styled.section`
  background-image: url(${({ image }) => image});
  background-position: center;
  background-size: cover;
`

const Hero = () => {
  return (
    <StyledHero image={background}>
      <div className="max-w-screen-xl px-4 py-56 mx-auto items-center flex">
        <div className="max-w-xl mx-auto text-center">
          <h1
            id="title"
            className="text-3xl font-extrabold text-white sm:text-8xl"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Wisła Oaza
          </h1>

          <p
            className="mt-4 sm:leading-relaxed text-white sm:text-3xl"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            Kościół Chrześcijan Baptystów w&nbsp;Wiśle
          </p>
        </div>
      </div>
    </StyledHero>
  )
}

export default Hero
