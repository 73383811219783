import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "../Button"

const Welcome = () => {
  return (
    <section className="max-w-screen-xl mx-auto py-8">
      <div className="px-4 py-16 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 ">
          <div
            className="relative z-10 "
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div className="relative h-full hidden lg:block">
              <StaticImage
                className="absolute"
                data-sal="slide-right"
                data-sal-easing="ease"
                data-sal-duration="500"
                height="700"
                src="../../assets/photo/pastor3.jpg"
                alt="pastor"
              />
            </div>
          </div>

          <div
            className="relative flex items-center bg-gray-100 lg:my-12"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <span className="hidden lg:inset-y-0 lg:absolute lg:w-16 lg:bg-gray-100 lg:block lg:-left-16"></span>

            <div className="p-4 sm:p-16 lg:py-24 lg:pl-6 lg:pr-32">
              <h2 className="text-2xl font-bold sm:text-3xl">Witaj w Oazie!</h2>
              <div className="md:float-left mt-8 mr-5 lg:hidden">
                <StaticImage
                  data-sal="slide-right"
                  data-sal-easing="ease"
                  data-sal-duration="500"
                  src="../../assets/photo/pastor3.jpg"
                  width="350"
                  alt="pastor"
                />
              </div>

              <p className="mt-8">
                Naszą społeczność rozumiemy jako duchową rodzinę, której
                jesteśmy częścią, przez żywą wiarę w Jezusa Chrystusa.{" "}
                <strong>Być razem</strong> to dla nas nie tyle wielkie hasło,
                ale podążanie za Bogiem oraz Jego Słowem, które kochamy. Do
                takiej społeczności zapraszamy każdego, kto pokochał Pana
                Jezusa.
              </p>
              <br />
              <p>
                Tutaj na pewno doświadczysz miłości, troski i więzi z ludźmi we
                wspólnej wierze i miłości do Pana Jezusa oraz posłuszeństwie
                wobec Słowa Bożego.
              </p>
              <br />
              <p>
                Chcemy, aby każdy mógł powiedzieć:{" "}
                <strong>"Mój zbór, moja duchowa rodzina, moja Oaza!".</strong>
              </p>

              <div className="flex">
                <Button
                  to="pastor"
                  target="_blank"
                  className="px-6 mt-8 py-3 text-sm ml-auto font-medium text-dark rounded-lg"
                >
                  O Pastorze
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Welcome
