import { Link } from "gatsby"
import styled from "styled-components"

export const Button = styled(Link)`
  background-color: ${({ color }) => (color ? color : `#262626`)};
  border: none;
  text-decoration: none;
  display: inline-block;
  color: white;
  &:hover {
    cursor: pointer;
  }
`
